<template>
  <resource-search @delete="() => this.$emit('delete')" @input="id => this.$emit('input', id)" module="maintenanceContractorTrades" display-name="trades" :show-delete="showDelete" :fetch-params="extraFetchParams" :value="value">
    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ result.name }}</p>
    </template>

    <template #selected="{ selected }">
      <p class="font-weight-bold mb-0">{{ selected.name }}</p>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from './ResourceSearch.vue'
export default {
  components: { ResourceSearch },
  props: {
    showDelete: {
      type: Boolean,
      default: false,
    },
    type: String,
    value: Number,
  },
  computed: {
    extraFetchParams() {
      return {};
    }
  }
}
</script>
<style>
</style>
