<template>
  <div class="search" :class="{ 'search--disabled': disabled }">
    <div v-if="!selected && !loadingSingle">
      <b-form-input class="mb-0" :disabled="disabled" :placeholder="$slots.placeholder ? $slots.placeholder[0].text : ( this.displayName ? 'Search for ' + this.displayName + '...' : 'Search for ' + this.module + '...')" tabindex="0" :debounce="500" :id="`search-field-${this._uid}`" @update="search" v-model="term"></b-form-input>
      <b-popover class="p-0 w-100" placement="bottom" :target="`search-field-${this._uid}`" triggers="focus" id="search-results">
        <div class="text-center">
          <p class="my-3 text-muted" v-if="!loading && !results.length">Please start typing to search for {{ displayName ? displayName : module }}.</p>
          <b-spinner class="mx-4 my-2" v-if="loading || !results"></b-spinner>
        </div>
        <b-list-group v-if="!loading && results">
          <b-list-group-item @click.prevent="select(result.id)" href="#" :key="result.id" v-for="result in results">
            <slot name="popover-list" :result="result" />
          </b-list-group-item>
        </b-list-group>
      </b-popover>
    </div>
    <b-card body-class="d-flex flex-row p-2" v-if="selected || loadingSingle">
      <div class="d-flex flex-column flex-fill justify-content-center mr-2">
        <b-spinner class="m-2" v-if="loadingSingle" />
        <slot name="selected" :selected="selected" v-if="!loadingSingle" />
      </div>
      <b-button @click.prevent="selected = null" variant="primary" v-if="!disabled"><fa-icon icon="pen" /></b-button>
      <b-button class="ml-2" @click.prevent="selected = null; $emit('delete', null)" variant="danger" v-if="!disabled && showDelete"><fa-icon icon="trash" /></b-button>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  async mounted() {
    if (this.value > 0) {
      this.loadingSingle = true;
      await this.fetchSingle(this.value).then(data => {
        this.loadingSingle = false;
        this.selected = data;
      });
    } else if(this.eager) {
      await this.fetch({ query: this.term, ...this.fetchParams })
    }
  },
  props: {
    disabled: Boolean,
    fetchParams: {
      type: Object,
      default: () => ({})
    },
    module: String,
    showDelete: {
      type: Boolean,
      default: false,
    },
    value: Number,
    eager: Boolean,
    displayName: String
  },
  data() {
    return {
      loadingSingle: false,
      selected: null,
      term: '',
    };
  },
  computed: {
    ...mapState({
      results(state) {
        return state[this.module].data;
      },
      loading(state) {
        return state[this.module].loading;
      }
    })
  },
  methods: {
    ...mapActions({
      fetch(dispatch, payload) {
        return dispatch(`${this.module}/fetch`, payload);
      },
      fetchSingle(dispatch, payload) {
        return dispatch(`${this.module}/fetchSingle`, payload);
      }
    }),
    select(id) {
      this.selected = this.results.find(x => x.id === id);
      this.$emit('input', id);
      this.$emit('selected', this.selected);
    },
    search() {
      this.$emit('search', this.term);
      return this.fetch({ query: this.term, ...this.fetchParams })
    }
  }
}
</script>

<style scoped>
div.search--disabled div.card {
  background-color: #e9ecef;
}

div.search-results {
  width: 100%;
}

div.popover {
  max-width: 500px!important;
  width: 100%;
  height: 400px;
  max-height: 80vh;
  overflow-y: auto;
}
</style>
