import get from 'lodash/get'

export default {
  data() {
    return {
      validationErrors: {},
    };
  },
  methods: {
    getValidationMessage(key) {
      return get(this.validationErrors, key, []).join('; ')
    },
    hasValidationErrors(err) {
      return get(err, 'response.status') === 422;
    },
    handleGenericError() {//err) {
      //
    },
    async handleValidationErrors(err) {
      const resp = await err.response.json();
      this.validationErrors = resp.errors;
    },
  }
}
