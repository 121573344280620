<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.maintenanceContractorId ? 'Edit' : 'Create'} Maintenance Contractor`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Company Name" :invalid-feedback="join('; ', validationErrors.name)" :state="!validationErrors.company_name">
                <b-form-input type="text" v-model="editedMaintenanceContractor.company_name" />
              </b-form-group>
              <b-form-group label="Contact Name" :invalid-feedback="join('; ', validationErrors.contact_name)" :state="!validationErrors.contact_name">
                <b-form-input rows="2" v-model="editedMaintenanceContractor.contact_name" />
              </b-form-group>
              <b-form-group label="Trade" :invalid-feedback="join('; ', validationErrors.trade_id)" :state="validationErrors.trade_id">
                <maintenance-contractor-trade-search v-model="editedMaintenanceContractor.trade_id" />
              </b-form-group>
              <b-form-group label="Email" :invalid-feedback="join('; ', validationErrors.email)" :state="!validationErrors.email">
                <b-form-input rows="4" v-model="editedMaintenanceContractor.email" />
              </b-form-group>
              <b-form-group label="Phone" :invalid-feedback="join('; ', validationErrors.phone)" :state="validationErrors.phone">
                <b-form-input type="text" v-model="editedMaintenanceContractor.phone" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveMaintenanceContractor" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.maintenanceContractorId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';
import MaintenanceContractorTradeSearch from "@/components/MaintenanceContractorTradeSearch.vue";

export default {
  components: {MaintenanceContractorTradeSearch},
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedMaintenanceContractor: {
        id: '',
        company_name: '',
        contact_name: '',
        email: '',
        phone: '',
        trade_id: undefined
      },
      uploading: false,
      loading: false,
      saving: false,
      error: ''
    };
  },
  props: {
    maintenanceContractor: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.maintenanceContractorId
      && this.maintenanceContractor
    ) {
      this.loading = true;

      try {
        const maintenanceContractor = await this.fetchSingle(this.$route.params.maintenanceContractorId);
        this.editedMaintenanceContractor = {
          ...maintenanceContractor
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load maintenance contractor. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('maintenanceContractors', ['fetch', 'fetchSingle', 'updateOrCreate']),
    join(join, arr) {
      if (arr && arr.length) {
        return arr.join(join)
      }

      return ''
    },
    closeModal() {
      this.editedMaintenanceContractor = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveMaintenanceContractor() {
      this.saving = true;
      this.updateOrCreate(this.editedMaintenanceContractor).then(() => {
        this.saving = false;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
  },
  watch: {
    maintenanceContractor(value) {
      if (value === undefined) {
        return
      }

      this.editedMaintenanceContractor = {
        ...value
      };
    },
  }
}
</script>

